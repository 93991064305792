import * as React from "react"
import Layout from "../components/common/Layout"
import { graphql } from "gatsby"
import styled from "styled-components"
import Story from "../components/stories/StoryBlock"
import Meta from "../components/common/Meta"
import Main from "../components/common/Main"
import PageIntro from "../components/common/intro/PageIntro"
import ApplyBox from "../components/common/formboxes/ApplyBox"

const StoriesContainer = styled.div`
  display: grid;
  justify-content: center;
  gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 6.25rem;
  width: min(1000px, 100% - 2.5rem);
  margin-inline: auto;
  margin-top: 2rem;
  @media (min-width: 40em) {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
  @media (min-width: 64em) {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 11.75rem;
  }
`

const FirstStory = styled(Story)`
  width: min(1000px, 100% - 2.5rem);
  border-color: #ffc2d1;
  margin-top: 3.5625rem;

  @media (min-width: 40em) {
    aspect-ratio: unset;
    max-height: 509px;
    max-width: unset;
    img {
      object-position: 0% 36% !important;
    }
  }

  transition: 0.1s ease-in-out;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      /*border-bottom: 5px solid #ffc2d1;*/
    }
  }
`

export const Head = () => (
  <Meta
    title="Rebase Stories - Inspiring Insights and Success Stories"
    description="Discover Rebase's inspiring stories. Learn about our people, success, and achievements in software, cloud, data, QA, and business agility."
  />
)

const StoriesPage = ({ data }) => {
  const title = [["Stories & Insights"]]
  let nodesCopy = [...data.allMarkdownRemark.nodes]
  nodesCopy.splice(0, 1)
  return (
    <Layout headerBgColor={"#7052cdcc"}>
      <Main>
        <PageIntro
          title={title}
          subheading="Curious to know more about us? You’ve come to the right place."
        />
        <FirstStory
          key={data.allMarkdownRemark.nodes[0].id}
          story={data.allMarkdownRemark.nodes[0].frontmatter}
        />
        <StoriesContainer>
          {nodesCopy.map(story => (
            <Story key={story.id} story={story.frontmatter} />
          ))}
        </StoriesContainer>
      </Main>
      <ApplyBox />
    </Layout>
  )
}

export const query = graphql`
  query Stories {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/src/data/stories/" } }
    ) {
      nodes {
        frontmatter {
          slug
          summaryTitle
          summary
          date
          stack
          summaryImage {
            childImageSharp {
              gatsbyImageData(quality: 50, placeholder: BLURRED)
            }
          }
        }
        id
      }
    }
  }
`

export default StoriesPage
